import React from "react"
import { Link, graphql } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"

import pic from '../../content/assets/2018_2.jpg'
class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title


    let MyAvi = {
      background: 'url(' + pic + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      boxShadow: `0 2px 6px 0 hsla(0,0%,0%,0.2)`,
      height: '80px',
      width: '80px',
      //float: 'left',
      //marginBottom: '0rem',
      marginRight: '2rem',
    }
      
    let al = function(e){
      e.preventDefault();
      alert("mihaica.niksa@gmail.com")
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          description=""
          keywords={[`Portfolio`, `Nikša`, `Mihaica`, `personal`, `website`]}
        />
      <div className="landing-wrapper">
        {/* <div className="is-clearfix">
          <div className="is-hidden" style={MyAvi}></div>
          <div className="biography is-clipped content">
          <h1>Hello.</h1>
              <p>I build digital things from Dubrovnik, Croatia. <br/> You can check some of the <Link to="/stories">stories</Link> I have written.  </p>
              <p>For collaboration, enquires reach me via <a onClick={al}>email.</a><br/>I am currently crafting at <a href="https://malloc.hr">malloc.hr</a></p>
              <p><a href="https://gitlab.com/nmihaica">gitlab</a> <a href="https://github.com/nmihaica">github</a> <a href="https://github.com/nmihaica">linkedin</a></p>  
          </div>
        </div> */}
  


        <div className="bio-wrapper columns is-variable is-8">
        {/* <img src="http://tinygraphs.com/labs/isogrids/hexa16/tinygraphs?theme=seascape&numcolors=4&size=80&fmt=svg" class="my-8"></img> */}
          {/* <figure className="filter-filter-1977 mb-4">
            <div className="avatar column" style={MyAvi}></div>
          </figure> */}

          <div className="bio-info column leading-5 is-4">
            <p>Hello.</p>
            <p>I am doing digital work from Dubrovnik, Croatia.<br/> You can check some of the <Link to="/stories">posts</Link> or dig through the <Link to="/tags">archive</Link>.</p>
            {/* <p>I do digital from Dubrovnik, Croatia. <br/> You can check some of the <Link to="/stories">posts</Link> I have written.  </p> */}
            <p>For collaboration, enquires, you can reach me <a className="" href="" onClick={al}>here</a>.</p>
            <p>Currently crafting at <a href="https://malloc.hr">malloc.hr</a></p>
            {/* <p><a href="https://gitlab.com/nmihaica">gitlab</a> <a href="https://github.com/nmihaica">github</a> <a href="https://github.com/nmihaica">linkedin</a></p> */}           
          </div>
        </div>
      </div>
    </Layout> 
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC },
      filter: {
        frontmatter: {
          published: {
            ne: false
          }
        }
      }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
